import * as React from "react"
import { graphql, useStaticQuery } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"

import Layout from "components/layout/layout"
import Seo from "components/layout/seo"

import ImgBackground from "components/heroes/ImgBackground"
import Section50Background from "components/heroes/Section50Background"
import TitleBox from "components/titles/Titlebox"

import TeamServiceBlock from "../components/blocks/TeamService"
import ContactCTABlock from "../components/blocks/ContactCTA"



const RooftopPage = () => {

    // using static header image, load here
    const data = useStaticQuery(
        graphql`
      query {
        heroImage: file(relativePath: { eq: "backgrounds/rooftop-solar.jpg" }) {
          childImageSharp {
            gatsbyImageData(
              width: 1200
            )
          }
        }
        quoteImage: file(relativePath: { eq: "general/pv-solar--rooftop-factory.jpeg" }) {
            childImageSharp {
              gatsbyImageData(
                width: 1200
              )
            }
          }
      }
    `
    )

    return (
        <Layout>
            <Seo title="Rooftop Solar" />

            {/* Title banner */}
            <ImgBackground image={data.heroImage}>
                <div className="container m-auto py-10 lg:pb-0 md:pt-40 lg:pt-60 lg:flex">
                    <TitleBox
                        title="Add rooftop solar to your business"
                        caption="Products"
                    >
                        <p className="text-2xl text-white font-normal">If you run a commercial or industrial (C&I) company, then the chances are you are keenly aware of the cost of energy. As the price of carbon goes up, traditional energy sources are only likely to increase in price. But you can hedge against future price rises today, by adopting solar PV.</p>
                    </TitleBox>
                </div>
            </ImgBackground>


            {/* Content Area */}
            <section className="page--content py-16 lg:py-32 lg:pb-16">
                <div className="container lg:flex lg:space-x-12">

                    <div className="content-area mb-12 lg:w-1/2">
                        <p className="text-leader">Solar power is already one of the most cost-effective sources of electricity in many parts of the world. Investing in solar power today allows you to benefit from a constant low price of electricity for decades to come.</p>
                        <p>And PV is completely renewable, helping you to achieve your corporate sustainability goals and join the likes of Apple, Intel and Ikea in combating climate change.</p>
                        <p>C&I solar, installed on rooftops or brownfield sites, sends your customers, employees and investors a strong signal that your business is committed to making a positive contribution to the planet.</p>
                    </div>

                    <div className="image-quote lg:w-1/2">
                        <StaticImage src="../images/general/rooftop-solar-fitting.jpg" width="600" height="300" alt="commercial rooftop solar" className="rounded-xl rounded-tl-none" />
                        <div className="p-8 bg-primary text-white rounded-xl text-3xl font-light max-w-lg relative float-right -mt-8 z-2 mb-8">We can help your solar plans come to life by taking care of all aspects of project design and realisation, from feasibility studies through to operations and maintenance.</div>
                        <div className="clear-both"></div>
                    </div>

                </div>
            </section>

            {/* 50/50 image pullquote divider */}
            <section className="bg-primary-50">
                <div className="container lg:flex">
                    <div className="lg:w-1/2">
                        <div className="p-8 md:p-16 lg:p-32 md:pl-0 lg:pl-0 text-2xl lg:text-2xl xl:text-3xl font-light max-w-4xl justify-items-end">
                            We can help your solar plans come to life by taking care of all aspects of project design and realisation, from feasibility studies through to operations and maintenance.<br /><br />
                            We can also integrate your solar systems with battery storage or electric vehicle charging systems to add further value to your investment in PV. Speak to us today about how you could benefit from adding rooftop solar PV to your business.
                        </div>
                    </div>
                    <div className="lg:w-1/2">
                        <div className="lg:float-left lg:w-1/2-screen h-80 lg:h-full">
                            <Section50Background image={data.quoteImage}>
                                <div className="bg-white bg-opacity-10 w-full h-full block">

                                </div>
                            </Section50Background></div>
                    </div>
                </div>
            </section>

            {/* team service block */}
            <section className="container lg:flex lg:space-x-32 py-16 lg:py-32">
                <TeamServiceBlock />
            </section>

            {/* CTA contact block */}
            <section className="py-16 lg:py-32">
                <ContactCTABlock />
            </section>

        </Layout>
    )
}

export default RooftopPage
